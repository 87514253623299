'use strict';

// Required Modules
import $ from 'jquery';

const SearchBar = function($el) {
  // The containing DOM element
  this.$el = $el;
  this.toggleSearchBar();
};

SearchBar.prototype.toggleSearchBar = function() {
  $(this.$el).click(function() {
    $('.js-search-bar-header').toggleClass('active');
    $('.js-search-whiteout').toggleClass('active');
    $('.js-search-outline').focus();

  });
};

export default SearchBar;
