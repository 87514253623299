import $ from 'jquery';

const Video = function($el) {
  this.$el = $el;
  this.loadPoster();
};

Video.prototype.loadPoster = function() {
  var poster = this.$el.data('poster');
  if(window.innerWidth < 1024 && poster.length > 0) { // don't if we are on something like desktop
    this.$el.attr('poster', poster);
  }
};

export default Video;
