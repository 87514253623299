'use strict';

// Required Modules
import $ from 'jquery';

const Menu = function($el) {
  // The containing DOM element
  this.$el = $el;

  this.toggleMenu();
};

Menu.prototype.toggleMenu = function() {
  $(this.$el).click(function() {
    $('.mast_nav').toggleClass('active');
  });
};

export default Menu;
