// Required modules for page load
'use strict';

import $ from 'jquery';
import Menu from './Menu';
import SearchBar from './SearchBar';
import SearchBarMobile from './SearchBarMobile';
import Player from './vendor/apm-html5-player/script/Player';
import Playlist from './vendor/apm-html5-player/script/Playlist';
import AudioAnalytics from './vendor/apm-html5-player/script/AudioAnalytics';
import FormatTime from './vendor/apm-html5-player/script/FormatTime';
import DataTables, { responsive } from 'datatables';
import Video from './Video';
import ExpandToggle from './ExpandToggle';

//var DataTables = require('datatables');
//var DataTablesResponsive = require('datatables.responsive');

// Constructor
const Main = function() {
  // The Time formatter utility
  this.formatTime = new FormatTime();

  this.init();
};

Main.prototype.init = function() {
  var self = this;

  if (document.getElementsByTagName('audio')[0]) {
    var player_analytics = new AudioAnalytics();
    player_analytics.init({ audio: document.getElementsByTagName('audio')[0] });
  }

  if ($('.js-datatables').length) {
    $('.js-datatables').DataTable({
      language: {
        aria: {
          paginate: {
            next: 'Next',
            previous: 'Previous'
          }
        },
        info: '<span class="text-bold">_START_-_END_</span> of _TOTAL_ rows',
        paginate: {
          next: '&raquo;',
          previous: '&laquo;'
        },
        search: '<span class="input-label input-label-hidden">Search table data:</span>',
        searchPlaceholder: 'Search table data'
      },
      lengthChange: false,
      order: [],
      responsive: true
    });
  }

  $('.js-player').each(function() {
    new Player($, $(this), self.formatTime, Playlist).init();
  });

  if ($('.js-menu-toggle').length) {
    var menu = new Menu($('.js-menu-toggle'));
  }
  if ($('.js-search-toggle').length) {
    var searchbar = new SearchBar($('.js-search-toggle'));
  }

  if ($('.js-search-mobile-toggle').length) {
    var searchbarmobile = new SearchBarMobile($('.js-search-mobile-toggle'));
  }

  if($('video').length) {
    new Video($('video'));
  }

  if($(".expand").length) {
    new ExpandToggle($('.expand'));
  }
};

export default Main;
