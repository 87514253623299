import $ from 'jquery';

const ExpandToggle = function($el) {
  this.$el = $el;
  this.toggle();
};

ExpandToggle.prototype.toggle = function() {
  this.$el.each(function(idx) {
    $(this).append("<div class='expandWrapper'></div>");
    var wrap = $(this).find('div.expandWrapper');

    $(this).children().each(function(idx) {
      if(idx === 0 ) return;
      if($(this).hasClass('expandWrapper')) return; 
      $(this).appendTo(wrap);
    })

    $(this).append("<div class='toggle'>More</div>");
  });

  $('.toggle').on('click', function() {
    $(this).prev().toggle('slow');
    if($(this).text() === 'More') {
      $(this).text('Less'); 
    }
    else {
      $(this).text('More'); 
    }
  });
};


export default ExpandToggle;
