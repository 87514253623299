'use strict';

// Required Modules
import $ from 'jquery';

const ACTIVE_CLASS = 'active';

const SearchBarMobile = function($el) {
  // The containing DOM element
  this.$el = $el;
  this.$icon = $('.js-icon-mobile-search');

  this.toggleSearchBarMobile();
};

SearchBarMobile.prototype.toggleSearchBarMobile = function() {
  var self = this;

  $(this.$el).on('keyup', function() {
    if($(this).val().length < 1) {
      self.$icon.removeClass(ACTIVE_CLASS);
    } else {
      self.$icon.addClass(ACTIVE_CLASS);
    }
  });
};

export default SearchBarMobile;
